import React from "react";
import { Container } from "react-bootstrap";
import HomeSection from "../../components/homeSection/HomeSection";
import "./Home.scss";

export const Home = (props) => {
  return (
    <Container className="text-center RootHome" fluid>
      <div className="RootHome-inside h-100" style={{ zIndex: "90" }}>
        <HomeSection properties={props.properties} />
      </div>
    </Container>
  );
};

export default Home;
