import React from "react";

export const PoweredBySuprive = () => {
  return (
    <div className="col-12 poweredSection">
      {/* <div className="hrLine" /> */}
      <div className="powered d-block d-lg-none">
        Powered by{" "}
        <a
          href="https://suprive.com/"
          id="#suprive"
          target="_blank"
          rel="noopener noreferrer"
        >
          Suprive
        </a>
      </div>
    </div>
  );
};
