import React, { useEffect } from "react";
import { Row, Col } from "react-bootstrap";
import "./HomeSection.scss";

import { animated } from "react-spring";

const trans1 = (x, y) => `translate3d(${x / 40}px,${y / 40}px,0)`;

export const HomeSection = (props) => {
  useEffect(() => {
    const script = document.createElement("script");
    script.src = 'https://suprive40873.activehosted.com/f/embed.php?id=140"';
    script.async = true;
    document.body.appendChild(script);

    const activeForms = document.getElementsByClassName("_form_140");
    const myForm = activeForms[0];
    myForm.addEventListener("submit", sendGtagConversionEvent);

    // const forms = myForm.getElementsByClassName("_form");
    // const form1 = forms[0];
    // console.log(form1);
    // const finalInput = inputSubDiv[0].getElementsByTagName("input");

    return () => {
      myForm.removeEventListener("submit", sendGtagConversionEvent);
    };
  }, []);

  const sendGtagConversionEvent = (event) => {
    const submitButton = document.querySelector("#_form_140_submit");
    if (submitButton.disabled) {
      const dataLayer = window.dataLayer || [];
      function gtag() {
        dataLayer.push(arguments);
      }
      function gtag_report_conversion(url) {
        // let callback = function () {
        //   // if (typeof url != "undefined") {
        //   //   window.location = url;
        //   // }
        // };
        gtag("event", "conversion", {
          send_to: "AW-744301573/kdDRCNmintoBEIXI9OIC",
          // event_callback: callback,
        });
        return false;
      }
      gtag("event", "conversion", {
        send_to: "AW-744301573/5nMNCMj_ndoBEIXI9OIC",
        value: 1.0,
        currency: "USD",
        aw_remarketing_only: true,
      });
      gtag_report_conversion("https://twerkealo.com");
    }
  };

  return (
    <Row className="homeSectionRow h-100">
      <Col xs={12} lg={6} className="homeSectionCol d-none d-lg-flex">
        <animated.div
          className="homeSectionImg"
          style={{ transform: props.properties.xy.interpolate(trans1) }}
        >
          <img
            alt=""
            data-aos="fade-up"
            data-aos-duration="1000"
            data-aos-offset="-400"
            className="imgHomeSection"
            src={require("../../../../assets/img/photos/oli-test 2-min-min.png")}
          ></img>
        </animated.div>
      </Col>
      <Col xs={12} lg={6} className="homeSectionColForm">
        <div className="formContent">
          <div className="position-relative formBgSize">
            <div style={{ width: "fit-content" }}>
              <div className="discountBg"></div>
              <h3 className="h3Title d-none d-lg-block">
                Reserva tu plaza al curso de <a>TWERKEALO</a> de <a>OLIVIA</a> y{" "}
                <a>LUCÍA</a> y llévate:
              </h3>
              <h3 className="h3Title d-block d-lg-none">
                Reserva tu plaza al curso de <a>TWERKEALO</a>
                <br />
                de <a>OLIVIA</a> y <a>LUCÍA</a> y llévate:
              </h3>
              <h1 className="h1Title">
                <a>10€ DE DESCUENTO</a>
                <br />
                <a>ACCESO ANTICIPADO</a>
              </h1>
              <h3 className="h3Title">una semana antes de su apertura</h3>
            </div>
            <div className="_form_140"></div>
          </div>
        </div>
      </Col>
      <Col xs={12} lg={6} className="homeSectionCol d-flex d-lg-none">
        <div className="homeSectionImg">
          <img
            alt=""
            data-aos="fade-up"
            data-aos-duration="1000"
            data-aos-offset="-400"
            className="imgHomeSection"
            src={require("../../../../assets/img/photos/oli-test 2-min-min.png")}
          ></img>
        </div>
      </Col>
      <Col xs={12} lg={6} className="twerkSectionCol d-block d-lg-none">
        <div className="twerkSectionImg2">
          <img
            alt=""
            data-aos="fade-up"
            data-aos-duration="1000"
            data-aos-offset="-400"
            className="imgTwerkSection"
            src={require("../../../../assets/img/photos/lucia-squeeze 1-min-min.png")}
          ></img>
        </div>
      </Col>
    </Row>
  );
};

export default HomeSection;
