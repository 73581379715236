import React, { useEffect } from "react";
import { useSpring } from "react-spring";

// PAGES
import Layout from "../layout/Layout";
import Home from "./landing/pages/home/Home";
import Twerk from "./landing/pages/twerk/Twerk";
import { Container } from "react-bootstrap";
// import Footer from "../components/footer/Footer";

export const isMobile = () => {
  const ua = navigator.userAgent;
  return /Android|Mobi/i.test(ua);
};

export const Main = (props) => {
  const calc = (x, y) => [
    x - window.innerWidth / 2,
    y - window.innerHeight / 2,
  ];
  const [properties, set] = useSpring(() => ({
    xy: [0, 0],
    config: { mass: 10, tension: 550, friction: 140 },
  }));

  useEffect(() => {
    if (typeof navigator === "undefined" || !isMobile()) {
      document.body.style.overflow = "hidden";
    }
  }, []);

  return (
    <Layout>
      <Container
        fluid
        id="inicio"
        onMouseMove={({ clientX: x, clientY: y }) => set({ xy: calc(x, y) })}
      >
        <Twerk properties={properties} />
        <Home properties={properties} />
      </Container>
    </Layout>
  );
};

export default Main;
