import React from "react";
import { Row, Col } from "react-bootstrap";
import "./TwerkSection.scss";
import CountDownTimer from "./components/CountDownTimer";

import { animated } from "react-spring";

const trans1 = (x, y) => `translate3d(${x / 40}px,${y / 40}px,0)`;
// const trans2 = (x, y) => `translate3d(${x / 8 + 35}px,${y / 8 - 230}px,0)`;
// const trans3 = (x, y) => `translate3d(${x / 6 - 250}px,${y / 6 - 200}px,0)`;
// const trans4 = (x, y) => `translate3d(${x / 3.5}px,${y / 3.5}px,0)`;

export const TwerkSection = (props) => {
  return (
    <Row className="twerkSectionRow h-100">
      <Col xs={12} lg={6} className="twerkSectionCol contentColTwerkSection">
        <div className="twerkSectionImg1">
          <img
            alt=""
            className="twerkealoLogo"
            src={require("../../../../assets/img/icons/twerkealo.svg")}
          ></img>
        </div>
        <div className="timerStyle">
          <CountDownTimer />
        </div>
      </Col>
      <Col xs={12} lg={6} className="twerkSectionCol d-none d-lg-flex">
        <animated.div
          className="twerkSectionImg2"
          style={{ transform: props.properties.xy.interpolate(trans1) }}
        >
          <img
            alt=""
            data-aos="fade-down"
            data-aos-duration="1000"
            data-aos-offset="-400"
            className="imgTwerkSection"
            src={require("../../../../assets/img/photos/lucia-squeeze 1-min-min.png")}
          ></img>
        </animated.div>
      </Col>
    </Row>
  );
};

export default TwerkSection;
