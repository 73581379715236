import React from "react";
import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";
import Main from "../views/Main";
import Maintenance from "../Maintenance";
import PoliticaDePrivacidad from "../views/pages/privacity/PoliticaDePrivacidad";

export const Router = () => {
  return (
    <BrowserRouter>
      <Switch>
        <Route exact path="/" component={Main} />
        {/* <Route exact path="/preview" component={Main} /> */}

        <Route
          path="/politica-de-privacidad"
          exact
          render={(props) => <PoliticaDePrivacidad {...props} />}
        />

        <Redirect to="/" />
      </Switch>
    </BrowserRouter>
  );
};

export default Router;
