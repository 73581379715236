import React, { useEffect } from "react";
import { Container } from "react-bootstrap";
import Cursor from "../components/cursor/Cursor";
import Canvas from "../components/canvas/Canvas";

// STYLE
import "./Layout.css";

// Libraries
import AOS from "aos";
import SmoothScroll from "smooth-scroll";
import { PoweredBySuprive } from "../components/powered/PoweredBySuprive";

export const isMobile = () => {
  const ua = navigator.userAgent;
  return /Android|Mobi/i.test(ua);
};

export const Layout = (props) => {
  useEffect(() => {
    AOS.init();
    new SmoothScroll('a[href*="#"]', { offset: 50 });
  });

  return (
    <Container nogutters="true" fluid id="inicio">
      {typeof navigator !== "undefined" && isMobile() ? null : <Canvas />}

      {/* <Navbar /> */}
      {typeof navigator !== "undefined" && isMobile() ? null : <Cursor />}
      {props.children}
      <PoweredBySuprive />
    </Container>
  );
};

export default Layout;
