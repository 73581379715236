import React from 'react';

import Timer from 'react-compound-timer';

import '../TwerkSection.scss';

export const CountDownTimer = () => {
  let currentDate = 1599555600000 - Date.now();

  return (
    <Timer initialTime={currentDate} direction="backward">
      {() => (
        <React.Fragment>
          <span className="timerDays">
            <Timer.Days />
          </span>
          <span className="timerHours">
            <Timer.Hours />
          </span>
          <span className="timerMinutes">
            <Timer.Minutes />
          </span>
          <span className="timerSeconds">
            <Timer.Seconds />
          </span>
          {/* <span>
            <Timer.Milliseconds /> milliseconds
          </span> */}
        </React.Fragment>
      )}
    </Timer>
  );
};

export default CountDownTimer;
