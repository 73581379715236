import React from "react";
import { Container } from "react-bootstrap";
import TwerkSection from "../../components/twerkSection/TwerkSection";
import "./Twerk.scss";

export const Twerk = (props) => {
  return (
    <Container className="text-center RootTwerk">
      <div className="RootTwerk-inside h-100" style={{ zIndex: "90" }}>
        <TwerkSection properties={props.properties} />
      </div>
    </Container>
  );
};

export default Twerk;
